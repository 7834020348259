
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
// import Multiselect from "@vueform/multiselect";
import ReportService from "@/core/services/car/ReportService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {yearList, roles} from "@/store/stateless/store";
import RecordSelect from "../components/recordSelect.vue";
import moment from "moment";
import { leadStatusMixin } from "@/core/plugins/vcMixins";
import UserService from "@/core/services/UserService";


interface IPayloadLBS {
  year: any;
  // month: number;
  start_date: any;
  end_date: any;
  sales: any;
  leads: any;
  per_page: number | string;
  page: number;
  agent:any;
  lead_source: string;

}

export default defineComponent({
  // component name
  name: "dashboard-details-report",
  components: {
    RecordSelect
  },
  data() {
    // constants
    return {};
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const value2 = ref<Array<string>>([]);

    let bodyPayloads = ref<IPayloadLBS>({
      year: "",
      // month: new Date().getMonth() + 1,.
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      sales: "all",
      leads: "total-new-leads",
      per_page: 50,
      page: 1,
      agent:"all",
      lead_source: "all"
    });

    let exportUrl = ref<string>("");

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    let yearsList = computed(() => {
      return store.getters.getLBSYears;
    });


  



    const defaultBodyPayloads = computed(() => {
      return store.getters.getCommonPayLoadLBS;
    });
    const displayResponseData = computed(() => {
      return store.getters.getDashboardDetails;
    });

    const myPagination = computed(() => {
      return store.getters.getExportPagination;
    });

    const getPaginationUrl = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyPayloads.value.page = currentPage.value;
      ReportService.getDashboardDetailsReport(bodyPayloads.value);
    };

    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyPayloads.value.per_page = event.target.value;
      ReportService.getDashboardDetailsReport(bodyPayloads.value);
    };

    const exportReport = () => {
      exportUrl.value = "";
      Promise.all([
        ReportService.exportDashboardDetailsReport(bodyPayloads.value)
      ]).then(() => {
        let exportResult = computed(() => {
          return store.getters.getExport;
        });
        exportUrl.value = '';
        const DownloadReportRoute = process.env.VUE_APP_API_URL + 'download-report/' + exportResult.value
        window.open(DownloadReportRoute)
      });
    };

    const onYearChange = () => {
      // bodyPayloads.value.year = event;
      bodyPayloads.value.page = 1;
      let firstDay = new Date(bodyPayloads.value.year, 1 - 1);
      let lastDay = new Date(bodyPayloads.value.year, 12, 0);
      value2.value = []
      setDateRange(firstDay, lastDay);
      ReportService.getDashboardDetailsReport(bodyPayloads.value);
    };

    const setDateRange = (firstDay, lastDay) => {
      bodyPayloads.value.start_date = moment(firstDay).format("YYYY-MM-DD");
      bodyPayloads.value.end_date = moment(lastDay).format("YYYY-MM-DD");
    };

    const onMonthChange = () => {
      // bodyPayloads.value.month = event;
      bodyPayloads.value.page = 1;
      ReportService.getDashboardDetailsReport(bodyPayloads.value);
    };

    

    const resetYear = (event) => {
      bodyPayloads.value.year = event == null ? "" : event;
      ReportService.getDashboardDetailsReport(bodyPayloads.value);
    };



    const resetAndRefreshFilter = () => {
      bodyPayloads.value = {
        year: "",
        // month: new Date().getMonth()+ 1,
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        sales: "all",
        leads: "total-new-leads",
        per_page: 50,
        page: 1,
        agent:"all",
        lead_source: "all"
      };
      ReportService.getDashboardDetailsReport(bodyPayloads.value);
      exportUrl.value = "";
    };

    onMounted(() => {
        if(!roles.reportLeadSource.includes(roles.userId())) window.location.href = '/#/404'

        
        if(route.query !== undefined && (route.query.sales !== undefined || route.query.leads !== undefined) )
        {
            const start_date = route.query.start_date;
            const end_date = route.query.end_date;

            const sales = route.query.sales !== undefined ? route.query.sales : 'all';
            const leads = route.query.leads !== undefined ? route.query.leads : 'all';

            
            bodyPayloads.value = {
                year: "",
                start_date: start_date,
                end_date:end_date,
                sales: sales,
                leads: leads,
                per_page: 50,
                page: 1,
                agent:"all",
                lead_source: "all"

            };

            value2.value = [bodyPayloads.value.start_date , bodyPayloads.value.end_date]; 
        }
        else
        {
            value2.value = [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
        }
        
        setCurrentPageBreadcrumbs("Dashboard Details", ["Reports"]);
        getSalesAgentList();
        ReportService.fetchingLeadSourceFilter();
        Promise.all([
            ReportService.getDashboardDetailsReport(bodyPayloads.value)
        ]).then((data) => {
            // console.log()
        });
      
    });

    const currentPage = ref(1);

    const user = computed(() => {
      return store.getters.currentUser;
    });
    const years = yearList.getYearList();
    
    const loading = computed(() => {
      return store.getters.getARLoading;
    });


    const searchByDate = (listPeriod) => {
      if(listPeriod) {
        var startTS = new Date(listPeriod[0]);
        var endTS = new Date(listPeriod[1]);
        bodyPayloads.value.year = ""
        bodyPayloads.value.start_date = moment(startTS.getFullYear() + "-" + (startTS.getMonth() + 1) + "-" + startTS.getDate()).format("YYYY-MM-DD");
        bodyPayloads.value.end_date = moment(endTS.getFullYear() + "-" + (endTS.getMonth() + 1) + "-" + endTS.getDate()).format("YYYY-MM-DD");
      } else {
        bodyPayloads.value.start_date = '';
        bodyPayloads.value.end_date = '';
      }
      bodyPayloads.value.page = 1;
      ReportService.getDashboardDetailsReport(bodyPayloads.value);
    };

    const salesOptions = ref(
     [
      {
        value: 'total-sales', 
        label: 'Total Sales'
      },
      {
        value: 'total-new-sales', 
        label: 'New Sales'
      },
      {
        value: 'total-renewal-sales', 
        label: 'Renewal Sales'
      },
      {
        value: 'total-llr-sales', 
        label: 'Lost Lead Renewal Sales'
      },
      {
        value: 'lifetime-renewal-leads', 
        label: 'Current Year Renewal Sales'
      },
    ]);

    const leadsOptions = ref(
     [
      {
        value: 'total-leads', 
        label: 'Total Leads'
      },
      {
        value: 'total-new-leads', 
        label: 'New Leads'
      },
      {
        value: 'total-renewal-leads', 
        label: 'Renewal Leads'
      },
      {
        value: 'total-llr-leads', 
        label: 'Lost Lead Renewals'
      },
      {
        value: 'total-lost-leads', 
        label: 'Lost Leads'
      },
      {
        value: 'total-lrl-leads', 
        label: 'Lead Renewal Lost'
      },
      {
        value: 'lifetime-renewal-leads', 
        label: 'Previous Year Renewal Leads'
      },
    ]);

    
    const onSalesChange = () => {
      bodyPayloads.value.page = 1;
      bodyPayloads.value.leads = "all";
      ReportService.getDashboardDetailsReport(bodyPayloads.value);
    };
    
    const onLeadChange = () => {
      bodyPayloads.value.page = 1;
      bodyPayloads.value.sales = "all";
      ReportService.getDashboardDetailsReport(bodyPayloads.value);
    };

    let salesAgentList = computed(() => {
      return store.getters.getDomSalesAgent;
    });
    const getSalesAgentList = () => {
      Promise.all([UserService.getDomSalesAgents({
        type: 'Car',
        isArchived: false,
        isActive: false
      })]).then((data) => {
        salesAgentList = computed(() => {
          return store.getters.getDomSalesAgent;
        });
       
      });
    };
    const onAgentChange = () => {
      ReportService.getDashboardDetailsReport(bodyPayloads.value);
    };

    const resetAgents = () => {
      ReportService.getDashboardDetailsReport(bodyPayloads.value);
    };

    let leadSources = computed(() => {
      return store.getters.getLBSSources;
    });
    return {
      leadSources,
      onAgentChange,
      resetAgents,
      salesAgentList,
      getSalesAgentList,
      onSalesChange,
      onLeadChange,
      leadsOptions,
      salesOptions,
      leadStatusMixin,
      searchByDate,
      loading,
      roles,
      value2,
      currentPage,
      bodyPayloads,
      defaultBodyPayloads,
      displayResponseData,
      myPagination,
      onPerPageChange,
      getPaginationUrl,
      resetAndRefreshFilter,
      exportUrl,
      exportReport,
      disableSaveButton,
      onYearChange,
      onMonthChange,
      resetYear,
      user,
      years
    }
  },
  methods: {
    indexMethod(index) {
      return (this.myPagination.current_page * this.myPagination.per_page) - this.myPagination.per_page + index + 1;
    }
  }

});
